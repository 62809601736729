import React from "react";
import { Link } from "gatsby";
import ClientLogo from "../components/client-logos";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";

const SecondPage = () => (
  <Layout title="Prop Maker - Clients">
    <h1 className="page-head">CLIENTS</h1>
    <ClientLogo />
    <Container>
      <h3 className="text-center py-5">And many more...</h3>
    </Container>
  </Layout>
);

export default SecondPage;
