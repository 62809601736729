import "./css/client-logo.css";
import React from "react";
import golem from "../images/client-logos/jetblue.png";
import Img from "gatsby-image";
import { Container, Row, Col, Button } from "reactstrap";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../images/client-logos", false, /\.(png|jpe?g|svg)$/)
);

const firstRow = [
  {
    src: images["disney.png"]
  },
  {
    src: images["nasa.png"]
  },
  {
    src: images["metrogoldwyn.png"]
  },
  {
    src: images["universalstudios.png"]
  }
];

const secondRow = [
  {
    src: images["dior.png"]
  },
  {
    src: images["redbull.png"]
  },
  {
    src: images["jetblue.png"]
  },
  {
    src: images["mazda.png"]
  }
];

const thirdRow = [
  {
    src: images["mcdonalds.png"]
  },
  {
    src: images["samsung.png"]
  },
  {
    src: images["toyota.png"]
  },
  {
    src: images["warnerbros.png"]
  }
];

const ClientLogo = () => (
  <div className="client-logo-component">
    <Container>
      <Row>
        <Col md="3" sm="6" xs="6">
          <img
            src={firstRow[0].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={firstRow[1].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={firstRow[2].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={firstRow[3].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="6" xs="6">
          <img
            src={secondRow[0].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={secondRow[1].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={secondRow[2].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={secondRow[3].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="6" xs="6">
          <img
            src={thirdRow[0].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={thirdRow[1].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={thirdRow[2].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
        <Col md="3" sm="6" xs="6">
          <img
            src={thirdRow[3].src}
            className="img-fluid"
            id="client-logo-image"
          />
        </Col>
      </Row>
    </Container>
  </div>
);

export default ClientLogo;
